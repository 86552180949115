<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'FAQ Group'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'FAQ Group Create'"
      :title="'FAQ Group Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div class="input-container">
            <MainCheckbox
              :label="'Show in main'"
              :id="'show_in_main'"
              :set-value="formDataToSend"
            />
          </div>
          <p class="form-create__label">Categories</p>
          <div class="input-container checkbox-container checkbox-container_margin">
            <div v-for="item in categoriesList" :key="item.id" class="checkbox-container__item">
              <MainCheckbox
                :label="item.fullName"
                :id="item.id + ''"
                :set-value="formDataToSend.categories"
              />
            </div>
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import categoriesApi from "~/api/categories";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "FaqGroupCreate",
  metaInfo: {
    title: "FAQ Group Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        show_in_main: false,
        status: false,
        categories: {},
      },
      categoriesList: [],
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
  },
  mounted() {
    this.getCategories();
  },
  computed: {
    ...mapState("faq", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.FAQ_GROUP_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        show_in_main: this.formDataToSend.show_in_main,
        status: this.formDataToSend.status,
      };
      const categories = Object.keys(this.formDataToSend.categories);
      if (categories.length) {
        let categoriesSend = [];
        for (let i = 0; categories.length > i; i++) {
          if (this.formDataToSend.categories[categories[i]] == true) {
            categoriesSend.push(categories[i]);
          }
        }
        if (categoriesSend.length > 0) {
          data.categories = categoriesSend;
        }
      }
      this.$store.dispatch("faq/createFaqGroup", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.FAQ_GROUP_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getCategories() {
      try {
        categoriesApi.getCategoriesActive().then((res) => {
          const data = res.data.items;
          this.categoriesList = data;
          this.setCategories(data);
        });
      } catch (error) {
        console.log(error);
      }
    },
    setCategories(data) {
      const categories = data;
      for (let i = 0; categories.length > i; i++) {
        this.$set(this.formDataToSend.categories, categories[i].id, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
